<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="header">
          <div class="header_title">
            <div class="deviceBack">
              <i class="el-icon-arrow-left" @click="back(nowType)"></i>
            </div>
            <span>订单详情-佛山市灏海餐饮管理有限公司</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="orderDMain">
          <div class="orderTil">订单号：{{ dataForm.id }}</div>
          <div class="goodsDetail">
            <div class="goodsTopTil">商品信息</div>
            <div
              class="orderGoodsList"
              v-for="(sitem, sindex) in dataForm.tbusinessCostVOS"
              :key="sindex"
            >
              <div class="rightGoods">
                <div class="goodsTils">{{ sitem.serviceName }}</div>
                <div class="goodsNum">数量:  X{{ sitem.count }}</div>
                <div class="goodsPrice">价格:  ￥{{ sitem.price}}</div>
              </div>
            </div>
          </div>
          <div class="orderBottom">
            <div class="totalPrice">总金额：{{ dataForm.totalCost }}</div>
          </div>
        </div>
        <div class="insideBottom">
          <div class="headTop">付款人信息</div>
          <div class="orderCellList">
            <div class="cellList">
              <div class="cellName">付款人：</div>
              <div class="cellValue">{{ dataForm.draweeName }}</div>
            </div>
            <div class="cellList">
              <div class="cellName">付款人电话：</div>
              <div class="cellValue">{{ dataForm.draweePhone}}</div>
            </div>
          </div>
          <div class="submitBtn">
            <el-button
                @click="moreBuy(item)"
                type="danger"
                size="mini"
                class="leftBtn"
                v-show="dataForm.status === -1"
            >重新支付</el-button>
          </div>

        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      item:[],
      isLogin: false,
      OrderStatus:"",
      barNum:"",
      nowType:0,
      btnloading: false,
      dataForm: {
        id: 0,
        buyId: null,
        cellAddress: null,
        deathAge: null,
        deathId: null,
        deathName: null,
        deathSex: null,
        draweeIdcard: null,
        draweeName: "",
        draweePhone: "",
        receivableCost: 0,
        reduceCost: 0,
        remarks: null,
        tbusinessCostVOS: [],
        totalCost: 0,
        status: 0,
      },
    }
  },
  mounted() {
    this.getDateList();
  },
  methods: {
    moment,
    getDateList(){
      // this.item = JSON.parse(JSON.stringify(this.$route.query.type));
      let id = this.$route.query.id;
      this.dataForm.id = this.$route.query.id;
      this.nowType = this.$route.query.type;
      this.$http.httpGet(`/mobile/onlineShop/load/${id}`).then(data => {
        if (data && data.code === 0) {
          this.dataForm = {...data.byRetail};
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    moreBuy() {
      this.btnloading = true;
      this.$http.httpPost(`mobile/onlineShop/reSave`, {
        id: this.dataForm.id,
        receivableCost: this.dataForm.receivableCost,
        totalCost: this.dataForm.totalCost,
        // returl: process.env.VUE_APP_PAY_URL + "payStatus.html?t=" + (+new Date()),
      }).then( data => {
        this.btnloading = false;
        if (data && data.code === 0) {
          if (data.status === 0) {
            this.$message.success("支付成功")
          } else {
            this.$message.success("该订单已支付，无法进行支付")
          }
          this.$router.replace({ name: "mine" });
        } else {
          if (data.status === 100) {
            this.$message.error("用户余额不足");
          } else if (data.status === 400) {
            this.$message.error("查询无此用户");
          } else {
            this.$message.error("无权限访问");
          }
        }
      }).catch(() => {
        this.btnloading = false;
      });
    },

    // 返回上一页
    back() {
      // this.$router.go(-1);
      this.$router.replace({
        name: "order",
        query: {
          type: this.nowType
        }});
      // console.log(nowType);
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .memberCode::v-deep {
      position: relative;
      padding: 0;
      margin: 0 0 15px;
      background-color: #fff;
      p {
        font-size: 16px;
        margin: 0;
        padding: 15px;
        text-align: left !important;
      }
      .vue-barcode-element {
        width: 100%;
      }
    }
    .el-header {
      padding: 0;
      margin: 0;
      height: 47px !important;
      background: #02A7F0;

      .header_title {
        height: 47px;
        text-align: center;
        color: white;
        line-height: 47px;
        font-size: 13px;
        border-bottom: 1px #E4E7ED solid;

        .deviceBack, .cancel {
          position: absolute;
          line-height: 47px;
          height: 47px;
          top: 0;
          left: 0;
          z-index: 9;
          padding: 0 10px;
          i {
            line-height: 47px;
            font-size: 24px;
          }
        }
        .cancel {
          left: auto;
          right: 0;
          font-size: 13px;
          color: #fff;
        }
      }
    }
    .el-main{
      padding: 10px 15px !important;
      background-color: #efefef;
      .reservateMain {
        position: relative;
        .reservateTop {
          position: relative;
          .reservateTopList {
            position: relative;
            overflow: hidden;
            margin: 0 0 20px;
            span {
              font-size: 16px;
              color: #000;
              padding: 0;
              margin: 0;
              line-height: 26px;
              float: left;
            }
            .statusBlock {
              font-size: 14px;
              border-radius: 12px;
              padding: 5px 15px;
              border: 1px #5e5e5e solid;
              color: #5e5e5e;
              display: inline-block;
              float: right;
            }
            .success {
              border-color: #54D25A;
              color: #54D25A;
              background-color: #E7F4D4;
            }
            .red {
              background-color: rgba(238, 43, 43, 0.7);
              border-color: rgba(238, 43, 43, 1);
              color: #fff;
            }
            .normal {
              background-color: rgba(65, 190, 249, 0.7);
              border-color: rgba(65, 190, 249, 1);
              color: #fff;
            }
          }
          p {
            font-size: 13px;
            color: #666;
            margin: 0 0 15px;
            padding: 0;
          }
        }
        .reservateCenter {
          position: relative;
          .reserBox {
            position: relative;
            overflow: hidden;
            width: calc(90vw - 30px);
            margin: 0 auto 20px;
            border: 1px #efefef solid;
            background-color: #fff;
            padding: 15px;
            .boxTop {
              font-size: 13px;
              color: #666;
              text-align: center;
              padding: 0 0 10px;
              border-bottom: 1px #efefef solid;
            }
            .reserListBox {
              padding: 15px 0 0;
              position: relative;
              .reserList {
                position: relative;
                display: flex;
                padding: 0 0 10px;
                .reserName {
                  font-size: 13px;
                  color: #666;
                  line-height: 1.4;
                }
                .reserAnswer {
                  font-size: 13px;
                  color: #666;
                  line-height: 1.4;
                  flex: 1;
                  text-align: right;
                }
              }
              .blueBig {
                .reserName, .reserAnswer {
                  color: #298FFF;
                  font-weight: 600;
                }
              }
            }

            .boxCode {
              position: relative;
              border-bottom: 1px #efefef solid;
              padding: 10px 0;
              overflow: hidden;
              .codeNum {
                font-size: 18px;
                color: #298fff;
                text-align: center;
              }
              .el-button {
                font-size: 12px;
                float: right;
                padding: 4px 15px;
                position: absolute;
                right: 0;
                z-index: 99;
                top: 8px;
              }
            }

            .codeBox {
              padding: 15px 0;
              p {
                font-size: 13px;
                text-align: center;
                color: #5e5e5e;
                margin: 0 0 5px;
                line-height: 1.4;
              }
              img {
                width: 40vw;
                display: block;
                margin: 25px auto;
              }
              .el-button {
                font-size: 12px;
                display: block;
                padding: 10px 15px;
                margin: 15px auto 0;
              }
            }

            .reserPaper {
              margin: 15px 0;
              position: relative;
              p {
                font-size: 13px;
                color: #333;
                line-height: 1.8;
                padding: 0;
                margin: 0;
                text-indent: 2em;
                span {
                  font-weight: 600;
                  color: #f00;
                }
              }
              &::-webkit-scrollbar {
                width: 0 !important
              }
            }
            &:last-child {
              padding: 15px 15px 0;
              margin: 0 auto;
            }
          }
        }
      }
    }

    .el-footer {
      background: #fff;
      color: #5e5e5e;
      height: 55px !important;
      border-top: 1px #efefef solid;
      .bottom {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        .bot_business, .bot_my, .member{
          flex: 1;
          text-align: center;
          .el-icon-circle-plus, .el-icon-s-order, .el-icon-user{
            font-size: 20px;
            margin: 8px 0 2px;
          }
          span {
            font-size: 13px;
            display: block;
            text-align: center;
            line-height: 1.6;
          }
        }
        .active {
          i, span {
            color: #298FFF;
          }
        }
      }
    }
  }
}
</style>
